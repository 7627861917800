import Logo from "@assets/images/logo_dark.svg";
import ButtonAppMarket from "@src/components/ButtonAppMarket";
import PageWrap from "@src/components/PageWrap";
import { PHONE_NUMBER } from "@src/constants";
import { metrikaGoal } from "@src/utils/metrikaGoal";
import { Block, Divider, Flexbox, Link, Paragraph } from "@stage-ui/core";

export const Footer = () => {
  return (
    <Block backgroundColor="gray800" py="3.5rem" mt="5.5rem">
      <PageWrap>
        <Flexbox direction={["row", "column"]} alignItems="center">
          <Link
            href="/"
            onClick={() => {
              metrikaGoal("clickFooderLogo");
            }}
          >
            <img src={Logo} style={{ width: "7.5rem" }} />
          </Link>
          <Flexbox
            column
            centered={[false, true]}
            my={[0, "2rem"]}
            flex={1}
            ml={["5.5rem", 0]}
          >
            <Paragraph
              color="green400"
              weight={500}
              size={["1.25rem", "m"]}
              mb="s"
            >
              Мы на связи:{" "}
              <Link
                decoration="none"
                color="green400"
                size={["1.25rem", "m"]}
                weight={500}
                href={`tel:${PHONE_NUMBER}`}
              >
                {PHONE_NUMBER}
              </Link>
            </Paragraph>
            <Link
              href="https://horeca-cloud.ru/bucket/docs/agreement_v1.pdf"
              style={{ textDecoration: "none" }}
              target="_blank"
              color="gray400"
              rel="noreferrer"
              onClick={() => {
                metrikaGoal("clickFooderDownloadAgreement");
              }}
            >
              Скачать договор-оферту
            </Link>
          </Flexbox>
          <Flexbox>
            <ButtonAppMarket
              mr="m"
              type="apple"
              style={{
                opacity: 1,
                filter: "invert(1)",
              }}
              onClick={() => {
                metrikaGoal("clickFooterAppDownloadApple");
              }}
            />
            <ButtonAppMarket
              type="google"
              style={{
                opacity: 1,
                filter: "invert(1)",
              }}
              onClick={() => {
                metrikaGoal("clickFooterAppDownloadGoogle");
              }}
            />
          </Flexbox>
        </Flexbox>
        <Divider color="gray700" my="xl" />
        <Paragraph color="gray400">
          {`© 2020-${new Date().getFullYear()} | `}
          <Link
            color="gray400"
            decoration="none"
            href="/policy"
            onClick={() => {
              metrikaGoal("clickFooterPrivacyLink");
            }}
          >
            Политика конфиденциальности
          </Link>
        </Paragraph>
      </PageWrap>
    </Block>
  );
};
